exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-articles-ai-usage-in-dev-jsx": () => import("./../../../src/pages/blog/articles/AiUsageInDev.jsx" /* webpackChunkName: "component---src-pages-blog-articles-ai-usage-in-dev-jsx" */),
  "component---src-pages-blog-blog-article-jsx": () => import("./../../../src/pages/blog/BlogArticle.jsx" /* webpackChunkName: "component---src-pages-blog-blog-article-jsx" */),
  "component---src-pages-blog-blog-entry-jsx": () => import("./../../../src/pages/blog/BlogEntry.jsx" /* webpackChunkName: "component---src-pages-blog-blog-entry-jsx" */),
  "component---src-pages-blog-blog-jsx": () => import("./../../../src/pages/blog/Blog.jsx" /* webpackChunkName: "component---src-pages-blog-blog-jsx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-sections-cloud-services-jsx": () => import("./../../../src/pages/landing-sections/CloudServices.jsx" /* webpackChunkName: "component---src-pages-landing-sections-cloud-services-jsx" */),
  "component---src-pages-landing-sections-contact-jsx": () => import("./../../../src/pages/landing-sections/Contact.jsx" /* webpackChunkName: "component---src-pages-landing-sections-contact-jsx" */),
  "component---src-pages-landing-sections-hero-jsx": () => import("./../../../src/pages/landing-sections/Hero.jsx" /* webpackChunkName: "component---src-pages-landing-sections-hero-jsx" */),
  "component---src-pages-landing-sections-partners-jsx": () => import("./../../../src/pages/landing-sections/Partners.jsx" /* webpackChunkName: "component---src-pages-landing-sections-partners-jsx" */),
  "component---src-pages-landing-sections-services-grid-jsx": () => import("./../../../src/pages/landing-sections/ServicesGrid.jsx" /* webpackChunkName: "component---src-pages-landing-sections-services-grid-jsx" */),
  "component---src-pages-landing-sections-services-jsx": () => import("./../../../src/pages/landing-sections/Services.jsx" /* webpackChunkName: "component---src-pages-landing-sections-services-jsx" */),
  "component---src-pages-landing-sections-stats-jsx": () => import("./../../../src/pages/landing-sections/Stats.jsx" /* webpackChunkName: "component---src-pages-landing-sections-stats-jsx" */),
  "component---src-pages-landing-sections-team-jsx": () => import("./../../../src/pages/landing-sections/Team.jsx" /* webpackChunkName: "component---src-pages-landing-sections-team-jsx" */),
  "component---src-pages-landing-sections-test-jsx": () => import("./../../../src/pages/landing-sections/Test.jsx" /* webpackChunkName: "component---src-pages-landing-sections-test-jsx" */),
  "component---src-pages-landing-sections-testimonials-jsx": () => import("./../../../src/pages/landing-sections/Testimonials.jsx" /* webpackChunkName: "component---src-pages-landing-sections-testimonials-jsx" */),
  "component---src-pages-layout-footer-jsx": () => import("./../../../src/pages/layout/Footer.jsx" /* webpackChunkName: "component---src-pages-layout-footer-jsx" */),
  "component---src-pages-layout-header-jsx": () => import("./../../../src/pages/layout/Header.jsx" /* webpackChunkName: "component---src-pages-layout-header-jsx" */),
  "component---src-pages-layout-layout-jsx": () => import("./../../../src/pages/layout/Layout.jsx" /* webpackChunkName: "component---src-pages-layout-layout-jsx" */),
  "component---src-pages-our-work-empowering-jsx": () => import("./../../../src/pages/our-work/Empowering.jsx" /* webpackChunkName: "component---src-pages-our-work-empowering-jsx" */),
  "component---src-pages-our-work-index-js": () => import("./../../../src/pages/our-work/index.js" /* webpackChunkName: "component---src-pages-our-work-index-js" */),
  "component---src-pages-our-work-our-work-jsx": () => import("./../../../src/pages/our-work/OurWork.jsx" /* webpackChunkName: "component---src-pages-our-work-our-work-jsx" */),
  "component---src-pages-our-work-pharma-jsx": () => import("./../../../src/pages/our-work/Pharma.jsx" /* webpackChunkName: "component---src-pages-our-work-pharma-jsx" */),
  "component---src-pages-our-work-streamline-jsx": () => import("./../../../src/pages/our-work/Streamline.jsx" /* webpackChunkName: "component---src-pages-our-work-streamline-jsx" */),
  "component---src-pages-our-work-volunteering-jsx": () => import("./../../../src/pages/our-work/Volunteering.jsx" /* webpackChunkName: "component---src-pages-our-work-volunteering-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/PrivacyPolicy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */),
  "component---src-pages-testimonials-testimonials-jsx": () => import("./../../../src/pages/testimonials/Testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-testimonials-jsx" */)
}

